import Vue from 'vue'
import Toasted from 'vue-toasted'

Vue.use(Toasted, {"position":"top-center"})

const globals = [{"name":"my-error","message":"Oops...Something went wrong","options":{"type":"error","duration":5000,"position":"top-center","icon":"warning"}},{"name":"my-success","message":"Working nice!","options":{"type":"success","duration":3000,"position":"top-center","icon":"check"}}]
if(globals) {
  globals.forEach(global => {
    Vue.toasted.register(global.name, global.message, global.options)
  })
}

export default function (ctx, inject) {
  inject('toast', Vue.toasted)
}
