const ENDPOINT_UPDATE_MY_PASSWORD = '/secure/me/update-password'
const ENDPOINT_UPDATE_MY_PROFILE = '/secure/me/profile'
const ENDPOINT_UPDATE_TEACHER_PROFILE = '/secure/me/teacher'
const ENDPOINT_ME = 'secure/me'
const ENDPOINT_AFFILIATES = '/affiliates/:tokenId'
const ENDPOINT_REGISTER = '/register'
const ENDPOINT_REQUEST_PASSWORD = '/request-password'
const ENDPOINT_RESET_PASSWORD = '/reset-password'

export const state = () => ({
  isLanding: false,
  authBackRoute: null,
  signupModal: false,
  currentLayout: false,
  currentAxiosRequests: 0,
  isGmapLoaded: false
})

export const mutations = {
  setIsLanding(state, payload) {
    state.isLanding = payload
  },
  setAuthBackRoute(state, payload) {
    state.authBackRoute = payload
  },
  setIP(state, payload) {
    state.ip = payload
  },
  setLoggedInUser(state, payload) {
    this.$auth.setUser(payload)
  },
  setSignupModal(state, payload) {
    state.signupModal = payload
  },
  setCurrentLayout(state, payload) {
    state.currentLayout = payload
  },
  incrementAxiosRequest(state) {
    state.currentAxiosRequests += 1
  },
  decrementAxiosRequest(state) {
    state.currentAxiosRequests -= 1
  },
  setGmapLoaded(state) {
    state.isGmapLoaded = true
  }
}

export const getters = {
  isAuthenticated(state) {
    if (state.auth) return state.auth.loggedIn
    else return false
  },
  loggedInUser(state) {
    if (state.auth) return state.auth.user
    else return null
  },
  getAuthBackRoute(state) {
    if (state.authBackRoute) return state.authBackRoute
    else return null
  },
  getSignupModal(state) {
    return state.signupModal
  },
  getCurrentLayout(state) {
    return state.currentLayout
  },
  numberAxiosRequests(state) {
    return state.currentAxiosRequests
  }
}

export const actions = {
  nuxtServerInit({ commit, dispatch }, { route, store, req, app }) {},

  async fetchIP({ commit }) {
    try {
      // throws TypeError this.$axios is undefined
      const response = await this.$axios.get('/', {
        params: { format: 'json' }
      })
      commit('setIP', response.data.ip)
    } catch (e) {
      // eslint-disable-next-line
      console.log('error', e)
      commit('setIP', null)
    }
  },

  async fetchMe({ commit }) {
    try {
      // throws TypeError this.$axios is undefined
      const response = await this.$axios.get(ENDPOINT_ME)
      commit('setLoggedInUser', response.data)
      return Promise.resolve(response.data)
    } catch (e) {
      // eslint-disable-next-line
      console.log('error', e)
      return Promise.reject(e)
    }
  },

  async updateMyPassword({ commit, rootState }, payload) {
    try {
      //      console.log(JSON.stringify(payload))
      // throws TypeError this.$axios is undefined
      const response = await this.$axios.post(
        `${ENDPOINT_UPDATE_MY_PASSWORD}`,
        payload
      )
      //      console.log(response)
      return Promise.resolve(response.data)
    } catch (e) {
      // eslint-disable-next-line
      console.log(e)
      return Promise.reject(e)
    }
  },

  async updateMyProfile({ commit, rootState }, payload) {
    try {
      //      console.log(JSON.stringify(payload))
      // throws TypeError this.$axios is undefined
      const response = await this.$axios.put(
        `${ENDPOINT_UPDATE_MY_PROFILE}`,
        payload
      )
      commit('setLoggedInUser', response.data.user)
      //      console.log(response)
      return Promise.resolve(response.data)
    } catch (e) {
      // eslint-disable-next-line
      console.log(e)
      return Promise.reject(e)
    }
  },

  async createTeacherProfile({ commit, rootState }, payload) {
    try {
      //      console.log(JSON.stringify(payload))
      // throws TypeError this.$axios is undefined
      const response = await this.$axios.post(
        `${ENDPOINT_UPDATE_TEACHER_PROFILE}`,
        payload
      )
      //      console.log(response)
      return Promise.resolve(response.data)
    } catch (e) {
      // eslint-disable-next-line
      console.log(e)
      return Promise.reject(e)
    }
  },

  async updateTeacherProfile({ commit, rootState }, payload) {
    try {
      //      console.log(JSON.stringify(payload))
      // throws TypeError this.$axios is undefined
      const response = await this.$axios.put(
        `${ENDPOINT_UPDATE_TEACHER_PROFILE}`,
        payload
      )
      //      console.log(response)
      return Promise.resolve(response.data)
    } catch (e) {
      // eslint-disable-next-line
      console.log(e)
      return Promise.reject(e)
    }
  },

  async fetchAffiliates({ commit }, payload) {
    try {
      // throws TypeError this.$axios is undefined
      const response = await this.$axios.get(
        ENDPOINT_AFFILIATES.replace(':tokenId', payload)
      )
      return Promise.resolve(response.data)
    } catch (e) {
      // eslint-disable-next-line
      console.log('error', e)
      return Promise.reject(e)
    }
  },

  async register({ commit }, payload) {
    try {
      // throws TypeError this.$axios is undefined
      const response = await this.$axios.post(ENDPOINT_REGISTER, payload)
      return Promise.resolve(response)
    } catch (e) {
      // eslint-disable-next-line
      console.log('error', e)
      return Promise.reject(e)
    }
  },

  async requestPassword({ commit }, payload) {
    try {
      // throws TypeError this.$axios is undefined
      const response = await this.$axios.post(
        ENDPOINT_REQUEST_PASSWORD,
        payload
      )
      return Promise.resolve(response)
    } catch (e) {
      // eslint-disable-next-line
      console.log('error', e)
      return Promise.reject(e)
    }
  },

  async resetPassword({ commit }, payload) {
    try {
      // throws TypeError this.$axios is undefined
      const response = await this.$axios.post(ENDPOINT_RESET_PASSWORD, payload)
      return Promise.resolve(response)
    } catch (e) {
      // eslint-disable-next-line
      console.log('error', e)
      return Promise.reject(e)
    }
  }
}
