export default function({ app }) {
  // beforeLanguageSwitch called right before setting a new locale
  app.i18n.beforeLanguageSwitch = (oldLocale, newLocale) => {}
  // onLanguageSwitched called right after a new locale has been set
  app.i18n.onLanguageSwitched = (oldLocale, newLocale) => {
    // TODO: Find a solution to activate these lines
    //    this.$moment.locale(newLocale)
    //    this.$axios.defaults.headers.common['Accept-Language'] = newLocale
  }
}
