<template lang="html">
  <div v-if="loading" class="loading-overlay">
    <md-progress-spinner :md-stroke="4" md-mode="indeterminate">
    </md-progress-spinner>
  </div>
</template>

<script>
import Vuex from 'vuex'

export default {
  data: () => ({
    loading: false
  }),
  watch: {
    numberAxiosRequests(val) {
      this.loading = !(val === 0)
    }
  },
  computed: {
    ...Vuex.mapGetters(['numberAxiosRequests'])
  },
  methods: {
    start() {
      this.loading = true
    },
    finish() {
      this.loading = false
    }
  }
}
</script>

<style scoped>
.loading-overlay {
  z-index: 10000;
  top: 0;
  right: 0;
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  align-items: center;
  justify-content: center;
}
</style>
