export default function({ $axios, store, redirect, app, nuxtState }) {
  // nuxtState exists only on client side
  if (nuxtState) {
    $axios.interceptors.request.use(
      (config) => {
        // before a request is made
        store.commit('incrementAxiosRequest')
        return config
      },
      function(error) {
        store.commit('incrementAxiosRequest')
        // eslint-disable-next-line
        console.log('Error before axios request: ', JSON.stringify(error))
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          app.$toast
            .error(app.i18n.t('error.' + error.response.data.message), {
              icon: 'warning'
            })
            .goAway(15000)
        } else {
          app.$toast.error(error, { icon: 'warning' }).goAway(15000)
        }
        return Promise.reject(error)
      }
    )

    $axios.interceptors.response.use(
      (response) => {
        // before a response is returned
        store.commit('decrementAxiosRequest')
        return response
      },
      function(error) {
        store.commit('decrementAxiosRequest')
        // eslint-disable-next-line
        console.log('Error before axios response: ', JSON.stringify(error))
        // const code = parseInt(error.response && error.response.status)
        // console.log(error.response)
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          app.$toast
            .error(app.i18n.t('error.' + error.response.data.message), {
              icon: 'warning'
            })
            .goAway(15000)
        } else {
          app.$toast.error(error, { icon: 'warning' }).goAway(15000)
        }
        return Promise.reject(error)
      }
    )
  }

  $axios.onRequest((config) => {
    config.headers.common['Accept-Language'] = store.$i18n.locale
  })
}
