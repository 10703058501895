import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1f68ab87 = () => interopDefault(import('../../pages/cgu.vue' /* webpackChunkName: "pages/cgu" */))
const _23d85096 = () => interopDefault(import('../../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _56c4e237 = () => interopDefault(import('../../pages/features.vue' /* webpackChunkName: "pages/features" */))
const _b78f2fc6 = () => interopDefault(import('../../pages/mentions-legales.vue' /* webpackChunkName: "pages/mentions-legales" */))
const _57ba8f78 = () => interopDefault(import('../../pages/prices.vue' /* webpackChunkName: "pages/prices" */))
const _160547d3 = () => interopDefault(import('../../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _0bdc9a12 = () => interopDefault(import('../../pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _009aad8e = () => interopDefault(import('../../pages/tutorials/index.vue' /* webpackChunkName: "pages/tutorials/index" */))
const _133b8bc8 = () => interopDefault(import('../../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _59c74c12 = () => interopDefault(import('../../pages/tutorials/account/create.vue' /* webpackChunkName: "pages/tutorials/account/create" */))
const _4f940b9f = () => interopDefault(import('../../pages/tutorials/account/invite.vue' /* webpackChunkName: "pages/tutorials/account/invite" */))
const _46783f7e = () => interopDefault(import('../../pages/tutorials/account/lost-password.vue' /* webpackChunkName: "pages/tutorials/account/lost-password" */))
const _c654280a = () => interopDefault(import('../../pages/tutorials/account/manage.vue' /* webpackChunkName: "pages/tutorials/account/manage" */))
const _49e17736 = () => interopDefault(import('../../pages/tutorials/community/file-pro.vue' /* webpackChunkName: "pages/tutorials/community/file-pro" */))
const _3fedbd74 = () => interopDefault(import('../../pages/tutorials/community/file-pro-seo.vue' /* webpackChunkName: "pages/tutorials/community/file-pro-seo" */))
const _a700f8c6 = () => interopDefault(import('../../pages/tutorials/community/place.vue' /* webpackChunkName: "pages/tutorials/community/place" */))
const _726ca22d = () => interopDefault(import('../../pages/tutorials/community/place-seo.vue' /* webpackChunkName: "pages/tutorials/community/place-seo" */))
const _57c5a882 = () => interopDefault(import('../../pages/tutorials/community/profile.vue' /* webpackChunkName: "pages/tutorials/community/profile" */))
const _e3d57992 = () => interopDefault(import('../../pages/tutorials/community/team.vue' /* webpackChunkName: "pages/tutorials/community/team" */))
const _9cee43ee = () => interopDefault(import('../../pages/tutorials/services/create.vue' /* webpackChunkName: "pages/tutorials/services/create" */))
const _41dae5eb = () => interopDefault(import('../../pages/tutorials/services/list.vue' /* webpackChunkName: "pages/tutorials/services/list" */))
const _4ddf2a65 = () => interopDefault(import('../../pages/tutorials/services/manual-registering.vue' /* webpackChunkName: "pages/tutorials/services/manual-registering" */))
const _48056fe0 = () => interopDefault(import('../../pages/tutorials/services/online.vue' /* webpackChunkName: "pages/tutorials/services/online" */))
const _610682f6 = () => interopDefault(import('../../pages/tutorials/services/plan.vue' /* webpackChunkName: "pages/tutorials/services/plan" */))
const _73386768 = () => interopDefault(import('../../pages/tutorials/services/price.vue' /* webpackChunkName: "pages/tutorials/services/price" */))
const _2b306c20 = () => interopDefault(import('../../pages/tutorials/services/seo.vue' /* webpackChunkName: "pages/tutorials/services/seo" */))
const _d2b3203a = () => interopDefault(import('../../pages/tutorials/services/validate.vue' /* webpackChunkName: "pages/tutorials/services/validate" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cgu",
    component: _1f68ab87,
    name: "cgu___fr___default"
  }, {
    path: "/contact",
    component: _23d85096,
    name: "contact___fr___default"
  }, {
    path: "/features",
    component: _56c4e237,
    name: "features___fr___default"
  }, {
    path: "/mentions-legales",
    component: _b78f2fc6,
    name: "mentions-legales___fr___default"
  }, {
    path: "/prices",
    component: _57ba8f78,
    name: "prices___fr___default"
  }, {
    path: "/reset-password",
    component: _160547d3,
    name: "reset-password___fr___default"
  }, {
    path: "/signup",
    component: _0bdc9a12,
    name: "signup___fr___default"
  }, {
    path: "/tutorials",
    component: _009aad8e,
    name: "tutorials___fr___default"
  }, {
    path: "/en/",
    component: _133b8bc8,
    name: "index___en"
  }, {
    path: "/en/cgu",
    component: _1f68ab87,
    name: "cgu___en"
  }, {
    path: "/en/contact",
    component: _23d85096,
    name: "contact___en"
  }, {
    path: "/en/features",
    component: _56c4e237,
    name: "features___en"
  }, {
    path: "/en/mentions-legales",
    component: _b78f2fc6,
    name: "mentions-legales___en"
  }, {
    path: "/en/prices",
    component: _57ba8f78,
    name: "prices___en"
  }, {
    path: "/en/reset-password",
    component: _160547d3,
    name: "reset-password___en"
  }, {
    path: "/en/signup",
    component: _0bdc9a12,
    name: "signup___en"
  }, {
    path: "/en/tutorials",
    component: _009aad8e,
    name: "tutorials___en"
  }, {
    path: "/fr/",
    component: _133b8bc8,
    name: "index___fr"
  }, {
    path: "/fr/cgu",
    component: _1f68ab87,
    name: "cgu___fr"
  }, {
    path: "/fr/contact",
    component: _23d85096,
    name: "contact___fr"
  }, {
    path: "/fr/features",
    component: _56c4e237,
    name: "features___fr"
  }, {
    path: "/fr/mentions-legales",
    component: _b78f2fc6,
    name: "mentions-legales___fr"
  }, {
    path: "/fr/prices",
    component: _57ba8f78,
    name: "prices___fr"
  }, {
    path: "/fr/reset-password",
    component: _160547d3,
    name: "reset-password___fr"
  }, {
    path: "/fr/signup",
    component: _0bdc9a12,
    name: "signup___fr"
  }, {
    path: "/fr/tutorials",
    component: _009aad8e,
    name: "tutorials___fr"
  }, {
    path: "/tutorials/account/create",
    component: _59c74c12,
    name: "tutorials-account-create___fr___default"
  }, {
    path: "/tutorials/account/invite",
    component: _4f940b9f,
    name: "tutorials-account-invite___fr___default"
  }, {
    path: "/tutorials/account/lost-password",
    component: _46783f7e,
    name: "tutorials-account-lost-password___fr___default"
  }, {
    path: "/tutorials/account/manage",
    component: _c654280a,
    name: "tutorials-account-manage___fr___default"
  }, {
    path: "/tutorials/community/file-pro",
    component: _49e17736,
    name: "tutorials-community-file-pro___fr___default"
  }, {
    path: "/tutorials/community/file-pro-seo",
    component: _3fedbd74,
    name: "tutorials-community-file-pro-seo___fr___default"
  }, {
    path: "/tutorials/community/place",
    component: _a700f8c6,
    name: "tutorials-community-place___fr___default"
  }, {
    path: "/tutorials/community/place-seo",
    component: _726ca22d,
    name: "tutorials-community-place-seo___fr___default"
  }, {
    path: "/tutorials/community/profile",
    component: _57c5a882,
    name: "tutorials-community-profile___fr___default"
  }, {
    path: "/tutorials/community/team",
    component: _e3d57992,
    name: "tutorials-community-team___fr___default"
  }, {
    path: "/tutorials/services/create",
    component: _9cee43ee,
    name: "tutorials-services-create___fr___default"
  }, {
    path: "/tutorials/services/list",
    component: _41dae5eb,
    name: "tutorials-services-list___fr___default"
  }, {
    path: "/tutorials/services/manual-registering",
    component: _4ddf2a65,
    name: "tutorials-services-manual-registering___fr___default"
  }, {
    path: "/tutorials/services/online",
    component: _48056fe0,
    name: "tutorials-services-online___fr___default"
  }, {
    path: "/tutorials/services/plan",
    component: _610682f6,
    name: "tutorials-services-plan___fr___default"
  }, {
    path: "/tutorials/services/price",
    component: _73386768,
    name: "tutorials-services-price___fr___default"
  }, {
    path: "/tutorials/services/seo",
    component: _2b306c20,
    name: "tutorials-services-seo___fr___default"
  }, {
    path: "/tutorials/services/validate",
    component: _d2b3203a,
    name: "tutorials-services-validate___fr___default"
  }, {
    path: "/en/tutorials/account/create",
    component: _59c74c12,
    name: "tutorials-account-create___en"
  }, {
    path: "/en/tutorials/account/invite",
    component: _4f940b9f,
    name: "tutorials-account-invite___en"
  }, {
    path: "/en/tutorials/account/lost-password",
    component: _46783f7e,
    name: "tutorials-account-lost-password___en"
  }, {
    path: "/en/tutorials/account/manage",
    component: _c654280a,
    name: "tutorials-account-manage___en"
  }, {
    path: "/en/tutorials/community/file-pro",
    component: _49e17736,
    name: "tutorials-community-file-pro___en"
  }, {
    path: "/en/tutorials/community/file-pro-seo",
    component: _3fedbd74,
    name: "tutorials-community-file-pro-seo___en"
  }, {
    path: "/en/tutorials/community/place",
    component: _a700f8c6,
    name: "tutorials-community-place___en"
  }, {
    path: "/en/tutorials/community/place-seo",
    component: _726ca22d,
    name: "tutorials-community-place-seo___en"
  }, {
    path: "/en/tutorials/community/profile",
    component: _57c5a882,
    name: "tutorials-community-profile___en"
  }, {
    path: "/en/tutorials/community/team",
    component: _e3d57992,
    name: "tutorials-community-team___en"
  }, {
    path: "/en/tutorials/services/create",
    component: _9cee43ee,
    name: "tutorials-services-create___en"
  }, {
    path: "/en/tutorials/services/list",
    component: _41dae5eb,
    name: "tutorials-services-list___en"
  }, {
    path: "/en/tutorials/services/manual-registering",
    component: _4ddf2a65,
    name: "tutorials-services-manual-registering___en"
  }, {
    path: "/en/tutorials/services/online",
    component: _48056fe0,
    name: "tutorials-services-online___en"
  }, {
    path: "/en/tutorials/services/plan",
    component: _610682f6,
    name: "tutorials-services-plan___en"
  }, {
    path: "/en/tutorials/services/price",
    component: _73386768,
    name: "tutorials-services-price___en"
  }, {
    path: "/en/tutorials/services/seo",
    component: _2b306c20,
    name: "tutorials-services-seo___en"
  }, {
    path: "/en/tutorials/services/validate",
    component: _d2b3203a,
    name: "tutorials-services-validate___en"
  }, {
    path: "/fr/tutorials/account/create",
    component: _59c74c12,
    name: "tutorials-account-create___fr"
  }, {
    path: "/fr/tutorials/account/invite",
    component: _4f940b9f,
    name: "tutorials-account-invite___fr"
  }, {
    path: "/fr/tutorials/account/lost-password",
    component: _46783f7e,
    name: "tutorials-account-lost-password___fr"
  }, {
    path: "/fr/tutorials/account/manage",
    component: _c654280a,
    name: "tutorials-account-manage___fr"
  }, {
    path: "/fr/tutorials/community/file-pro",
    component: _49e17736,
    name: "tutorials-community-file-pro___fr"
  }, {
    path: "/fr/tutorials/community/file-pro-seo",
    component: _3fedbd74,
    name: "tutorials-community-file-pro-seo___fr"
  }, {
    path: "/fr/tutorials/community/place",
    component: _a700f8c6,
    name: "tutorials-community-place___fr"
  }, {
    path: "/fr/tutorials/community/place-seo",
    component: _726ca22d,
    name: "tutorials-community-place-seo___fr"
  }, {
    path: "/fr/tutorials/community/profile",
    component: _57c5a882,
    name: "tutorials-community-profile___fr"
  }, {
    path: "/fr/tutorials/community/team",
    component: _e3d57992,
    name: "tutorials-community-team___fr"
  }, {
    path: "/fr/tutorials/services/create",
    component: _9cee43ee,
    name: "tutorials-services-create___fr"
  }, {
    path: "/fr/tutorials/services/list",
    component: _41dae5eb,
    name: "tutorials-services-list___fr"
  }, {
    path: "/fr/tutorials/services/manual-registering",
    component: _4ddf2a65,
    name: "tutorials-services-manual-registering___fr"
  }, {
    path: "/fr/tutorials/services/online",
    component: _48056fe0,
    name: "tutorials-services-online___fr"
  }, {
    path: "/fr/tutorials/services/plan",
    component: _610682f6,
    name: "tutorials-services-plan___fr"
  }, {
    path: "/fr/tutorials/services/price",
    component: _73386768,
    name: "tutorials-services-price___fr"
  }, {
    path: "/fr/tutorials/services/seo",
    component: _2b306c20,
    name: "tutorials-services-seo___fr"
  }, {
    path: "/fr/tutorials/services/validate",
    component: _d2b3203a,
    name: "tutorials-services-validate___fr"
  }, {
    path: "/",
    component: _133b8bc8,
    name: "index___fr___default"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
