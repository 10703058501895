import Vue from 'vue'
import VeeValidate from 'vee-validate'

import fr from 'vee-validate/dist/locale/fr'

Vue.use(VeeValidate, {})

VeeValidate.Validator.localize('fr', fr)

export default ({ app }, inject) => {
  app.validator = VeeValidate.Validator
}
