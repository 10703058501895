import Vue from 'vue'
// Validation plugin used to validate forms
// import * as VeeValidate from 'vee-validate'
import VueMaterial from 'vue-material'
// import 'vue-material/dist/vue-material.min.css'
// import '@/assets/scss/material-kit.scss'
// import '@/assets/css/mkDemo.css'
import VueLazyload from 'vue-lazyload'
import VueGitHubButtons from 'vue-github-buttons'
import globalDirectives from './kitPro/globalDirectives'
import globalMixins from './kitPro/globalMixins'
import basicMixins from './kitPro/basicMixins'
import globalComponents from './kitPro/globalComponents'
// import 'vue-github-buttons/dist/vue-github-buttons.css'

Vue.use(VueMaterial)
Vue.use(globalDirectives)
Vue.use(globalMixins)
Vue.use(basicMixins)
Vue.use(globalComponents)
Vue.use(VueLazyload, {
  observer: true,
  // optional
  observerOptions: {
    rootMargin: '0px',
    threshold: 0.1
  }
})
Vue.use(VueGitHubButtons, { useCache: false })
// Vue.use(VeeValidate, { fieldsBagName: 'veeFields' })

export default (_) => {}
