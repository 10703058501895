'use strict'

import axios from '~/plugins/axios-node'

export const state = () => ({})

export const mutations = {}

export const getters = {}

export const actions = {
  async sendEmail({ state }, payload) {
    const emailInfo = payload
    try {
      const response = await axios.post('/api/nodemailer', {
        emailInfo
      })
      return Promise.resolve(response.data)
    } catch (e) {
      // eslint-disable-next-line
      console.log('error sendEmail: ', e)
    }
  }
}
